process.env.NODE_TLS_REJECT_UNAUTHORIZED = 0

const _ = require("lodash")
const { formatConfig } = require("./src/utils/config-formatter")

// Request settings
const api = {
  dev: "http://localhost:1337",
  staging: "https://cms2.skillstrainer.in/api",
  production: "https://cms2.skillstrainer.in/api",
}

const pythonApi = {
  dev: "http://localhost:3000",
  staging: "https://admin.skillsscale.in/api",
  production: "https://adminapi.skillstrainer.in/api",
}

const graph = {
  dev: "https://graphql.skillsscale.in/v1/graphql",
  staging: "https://graphql.skillsscale.in/v1/graphql",
  production: "https://graphql.skillstrainer.in/v1/graphql",
}

const webapp = {
  dev: "http://localhost:3000",
  staging: "https://webapp2.skillsscale.in",
  production: "https://www.webapp.skillstrainer.in",
}

const admin = {
  dev: "http://localhost:3001",
  staging: "https://stageadmin.skillsscale.in",
  production: "https://admin.skillstrainer.in",
}

const s3BaseUrl = {
  dev: "https://adminskillstrainer.s3.ap-south-1.amazonaws.com",
  staging: "https://adminstagingploads.s3.ap-south-1.amazonaws.com",
  production: "https://adminskillstrainerprod.s3.ap-south-1.amazonaws.com",
}

// Languages
const languages = {
  English: "en",
  हिंदी: "hi",
  বাংলা: "bn",
  मराठी: "mh",
  ಕನ್ನಡ: "kn",
  ગુજરાતી: "gj",
  தமிழ்: "tm",
  తెలుగు: "tg",
}

const languagesInitials = {
  en: "En",
  hi: "हिं",
  bn: "বা",
  mh: "म",
  kn: "ಕ",
  gj: "ગુ",
  tm: "த",
  tg: "తె",
}

const reverseLanguages = _.invert(languages)

// Pages
const pages = {
  home: {
    component: "home-pages",
    path: "",
  },
  about: {
    component: "about-pages",
    path: "about",
  },
  privacy: {
    component: "privacy-policy-pages",
    path: "privacy",
  },
}
const YOUR_API_KEY = "AIzaSyDN7t9qNzubTNLP2AQfPmmyXCJf7xjaHY4"

const isSsr = typeof window === "undefined"
const beingConsumedByApp = !(
  typeof process !== "undefined" && process.env.runningBackendScripts
)

module.exports = formatConfig({
  api,
  pythonApi,
  webapp,
  admin,
  graph,
  languages,
  languagesInitials,
  reverseLanguages,
  pages,
  isSsr,
  beingConsumedByApp,
  s3BaseUrl,
  englishStarCourseId: 31,
  garimaCourseId: 222,
  YOUR_API_KEY,
})
